import { useLocation } from '@remix-run/react';

import { z } from 'zod';

const workspaceSchema = z.enum(['app', 'management', 'admin']);

export function useWorkspace() {
  const location = useLocation();
  const workspaceElement = location.pathname.split('/')?.[1];
  const parsed = workspaceSchema.safeParse(workspaceElement);
  return parsed.success ? parsed.data : undefined;
}
