import { useMatches } from '@remix-run/react';

import { useAuthenticatedLayoutData } from '~/routes/_authenticated+/_layout.tsx';

import type { Permission } from '.prisma/client';
import type { Role, User, UserInOrganization } from '@prisma/client';
import { useMemo } from 'react';

export function useMatchesData(id: string) {
  const matchingRoutes = useMatches();
  const route = useMemo(
    () => matchingRoutes.find((route) => route.id === id),
    [matchingRoutes, id],
  );
  return route?.data;
}

function isUser(user: any): user is UserWithRole {
  return user && typeof user === 'object' && typeof user.fullName === 'string';
}

type UserWithRole = User & { role: RoleWithPermissions } & { organizations: UserInOrganization[] };
type RoleWithPermissions = Role & { permissions: Permission[] };

export function useUser() {
  const loaderData = useAuthenticatedLayoutData();
  if (!loaderData?.user) {
    throw new Error('Missing user in authenticated context');
  }
  return loaderData.user;
}

export function useOptionalUser() {
  const data = useMatchesData('routes/_authenticated+/_layout') as { user?: UserWithRole };
  if (!data || !isUser(data.user)) {
    return undefined;
  }
  return data.user;
}

export function useProduction() {
  const data = useMatchesData('root') as { production?: boolean };
  return data?.production;
}
