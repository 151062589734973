import { cn } from '~/utils/css/css';
import { useFeature } from '~/utils/general/use-feature-flags.ts';

import type { ReactNode } from 'react';

interface HeaderProps {
  children?: ReactNode;
  className?: string;
}

export const PageHeaderGroup = ({ children }: { children?: ReactNode }) => {
  return <hgroup className={'space-y-1.5'}>{children}</hgroup>;
};

export const PageHeader = ({ children, className }: HeaderProps) => {
  const serifFont = useFeature('serif-headers');
  return (
    <h1 className={cn('font-medium text-4xl', className, serifFont && 'font-kaisei')}>{children}</h1>
  );
};

export const PageSectionHeader = ({ children }: { children: ReactNode }) => {
  return <hgroup className={'space-y-1'}>{children}</hgroup>;
};

export const PageSectionTitle = ({ children }: HeaderProps) => {
  return <h3 className={'font-medium text-xl'}>{children}</h3>;
};

export const PageSectionDescription = ({ children }: HeaderProps) => {
  return <p className={'text-muted-foreground text-sm'}>{children}</p>;
};

export const SubHeaderGroup = ({ children }: HeaderProps) => {
  return <div className={'space-y-2'}>{children}</div>;
};

export const SubHeader = ({ children }: HeaderProps) => {
  return <h2 className={'text-sm'}>{children}</h2>;
};

export const SubDescription = ({ children }: HeaderProps) => {
  return <p className={'text-muted-foreground text-sm'}>{children}</p>;
};

export const PageDescription = ({ children, className }: HeaderProps) => {
  return <p className={cn('text-muted-foreground text-sm', className)}>{children}</p>;
};
