import { ReactNode, createContext, useContext } from 'react';

interface DrawerDialogContextProps {
  variant: "dialog" | "drawer";
}

const DrawerDialogContext = createContext<DrawerDialogContextProps | undefined>(undefined);

export const DrawerDialogProvider = ({
  children,
  variant,
}: {
  children: ReactNode;
  variant: "dialog" | "drawer";
}) => {
  return (
    <DrawerDialogContext.Provider value={{ variant }}>{children}</DrawerDialogContext.Provider>
  );
};

export const useDrawerDialogContext = (): DrawerDialogContextProps => {
  const context = useContext(DrawerDialogContext);
  if (!context) {
    throw new Error('useDrawerDialog must be used within a DrawerDialogProvider');
  }
  return context;
};
