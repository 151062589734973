import { FeatureFlagKey } from '~/lib/constants/feature-flags.ts';
import { useAuthenticatedLayoutData } from '~/routes/_authenticated+/_layout.tsx';

import { FeatureFlag } from '@prisma/client';

export function useFeatureFlags(): FeatureFlag[] {
  const loaderData = useAuthenticatedLayoutData();
  return loaderData?.featureFlags ?? [];
}

export function useAvailableFeatureFlags(): FeatureFlag[] | undefined {
  const loaderData = useAuthenticatedLayoutData();
  return loaderData?.availableFeatureFlags;
}

export function useFeature(key: FeatureFlagKey) {
  const featureFlags = useFeatureFlags();
  return featureFlags.some((flag) => flag.key === key);
}
