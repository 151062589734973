import { LoaderFunctionArgs } from '@remix-run/node';
import { Outlet, useLoaderData, useRouteLoaderData } from '@remix-run/react';

import { ErrorComponent } from '~/components/features/error/error-component';
import { ImpersonationInfo } from '~/components/features/user/impersonation-info.tsx';
import { Dock } from '~/components/ui/dock.tsx';

import {
  OrganizationContext,
  UserOrganizationsContext,
  organizationMiddleware,
} from '~/utils/middleware/organization-middleware.server.ts';
import {
  FeatureFlagContext,
  UserContext,
  UserRoleContext,
  userMiddleware,
} from '~/utils/middleware/user-middleware.server.ts';
import { getAuthenticationSession } from '~/utils/session/authentication.server.ts';

import { findFeatureFlags } from '~/models/feature-flags.server.ts';
import { useNotificationChanges } from '~/modules/notification/use-notification-changes.ts';

import { $routeId } from 'remix-routes';
import { serverOnly$ } from 'vite-env-only/macros';

export const middleware = serverOnly$([userMiddleware, organizationMiddleware]);

export const loader = async ({ request, context }: LoaderFunctionArgs) => {
  const user = context.get(UserContext);
  const currentOrganization = context.get(OrganizationContext);
  const [authenticationSession, availableFeatureFlags] = await Promise.all([
    getAuthenticationSession(request),
    findFeatureFlags(),
  ]);
  const workspace = context.getWorkspace();
  const userRoles = context.get(UserRoleContext);
  const userOrganizations = context.get(UserOrganizationsContext);
  const permissions = userRoles.flatMap((role) => role.permissions);
  const workspaces = userRoles.flatMap((role) => role.workspaces);
  const featureFlags = context.get(FeatureFlagContext);
  return {
    user,
    featureFlags,
    userOrganizations,
    permissions,
    workspaces,
    workspace,
    availableFeatureFlags: user.administrative ? availableFeatureFlags : [],
    currentOrganization,
    impersonating: !!authenticationSession.getImpersonationId(),
  };
};

export function useAuthenticatedLayoutData() {
  return useRouteLoaderData<typeof loader>($routeId('routes/_authenticated+/_layout'));
}

const RootLayout = () => {
  const { impersonating } = useLoaderData<typeof loader>();
  const eventData = useNotificationChanges();

  return (
    <div className={'h-full'}>
      {impersonating && <ImpersonationInfo />}
      <Dock hasUnreadNotifications={eventData?.count !== undefined && eventData.count > 0} />
      <div className={'scrollbar-hide thin-scrollbars'}>
        <Outlet />
      </div>
    </div>
  );
};

export default RootLayout;

export const ErrorBoundary = () => {
  return <ErrorComponent />;
};
