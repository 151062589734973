import { useRouteLoaderData } from '@remix-run/react';

import { $routeId } from 'remix-routes';

export function useWorkspaces() {
  const loaderData = useRouteLoaderData<{ workspaces: string[] }>(
    $routeId('routes/_authenticated+/_layout'),
  );
  return loaderData?.workspaces ?? [];
}
