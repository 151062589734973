import { useNavigate } from '@remix-run/react';

import { useWorkspaces } from '~/utils/hooks/use-permissions.ts';
import { useWorkspace } from '~/utils/hooks/use-workspace.ts';

import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

export const DockWorkspaceSelector = ({ onSelect }: { onSelect?: (workspace: string) => void }) => {
  const { t } = useTranslation();
  const currentWorkspace = useWorkspace();
  const availableWorkspaces = useWorkspaces();

  const options = [
    {
      value: 'app',
      title: t('workspaces.app'),
      show: availableWorkspaces.includes('app'),
    },

    {
      value: 'management',
      title: t('workspaces.management'),
      show: availableWorkspaces.includes('management'),
    },
    { value: 'admin', title: t('workspaces.admin'), show: availableWorkspaces.includes('admin') },
  ];
  const navigate = useNavigate();

  const selectWorkspace = (workspace: string) => navigate(`/${workspace}`);
  return (
    <motion.div
      initial={{ y: 10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.27 }}
      className={'flex gap-2 items-center bg-background my-3'}>
      {options.map((option) => {
        if (!option.show) {
          return null;
        }
        return (
          <button
            onClick={() => {
              selectWorkspace(option.value);
              onSelect && onSelect(option.value);
            }}
            key={option.value}
            type={'button'}
            className={'relative rounded-full border text-sm border-muted px-3 py-1.5'}>
            {currentWorkspace === option.value && (
              <motion.div
                style={{ borderRadius: 999 }}
                layoutId={'workspace-selector'}
                className={'absolute inset-0 bg-primary z-10'}></motion.div>
            )}
            <span className={'relative text-white mix-blend-difference z-20'}>{option.title}</span>
          </button>
        );
      })}
    </motion.div>
  );
};
