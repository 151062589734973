import { NotificationWithForUsersAndReadBy } from '~/modules/notification/channel.server.ts';

import { Notification } from '@prisma/client';
import { $path } from 'remix-routes';
import { useEventSource } from 'remix-utils/sse/react';

interface NotificationChangeEvent {
  count?: number;
  notification?: NotificationWithForUsersAndReadBy;
  type: 'create' | 'update' | 'delete';
}

function parseNotificationsEvent(event: string | null) {
  if (!event) {
    return null;
  }
  try {
    return JSON.parse(event) as NotificationChangeEvent;
  } catch (e) {
    return null;
  }
}

export function useNotificationChanges() {
  const event = useEventSource($path('/api/sse/notification-count'), {
    event: 'unreadNotifications',
  });
  return parseNotificationsEvent(event);
}

export function useLiveNotifications<T extends Notification>(currentNotifications: T[]) {
  const event = useNotificationChanges();
  if (!event || !event.notification) {
    return currentNotifications;
  }
  switch (event.type) {
    case 'create': {
      return [event.notification, ...currentNotifications];
    }
    case 'update': {
      return currentNotifications;
    }
    case 'delete': {
      return currentNotifications.filter(
        (notification) => notification.id !== event.notification?.id,
      );
    }
  }
}
