import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '~/components/ui/dialog.tsx';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerTitle,
  DrawerTrigger,
} from '~/components/ui/drawer.tsx';
import { PageDescription, PageHeader, PageHeaderGroup } from '~/components/ui/page-header.tsx';

import {
  DrawerDialogProvider,
  useDrawerDialogContext,
} from '~/utils/context/dialog-drawer-context.tsx';

import * as DialogPrimitive from '@radix-ui/react-dialog';
import * as React from 'react';
import { Drawer as DrawerPrimitive } from 'vaul';

interface DrawerDialogProps
  extends Omit<React.ComponentProps<typeof DrawerPrimitive.Root>, 'fadeFromIndex' | 'snapPoints'> {
  variant: 'dialog' | 'drawer';
}

export const DrawerDialog = ({
  shouldScaleBackground = true,
  variant,
  ...props
}: DrawerDialogProps) => {
  const Comp = variant === 'dialog' ? DialogPrimitive.Root : Drawer

  return (
    <DrawerDialogProvider variant={variant}>
      <Comp {...props} shouldScaleBackground={shouldScaleBackground}></Comp>
    </DrawerDialogProvider>
  );
};

export const DrawerDialogTrigger = (
  props?: React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Trigger>,
) => {
  const { variant } = useDrawerDialogContext();
  const Comp = variant === 'dialog' ? DialogTrigger : DrawerTrigger;
  return <Comp {...props} />;
};

export const DrawerDialogContent = (
  props?: React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Content>,
) => {
  const { variant } = useDrawerDialogContext();
  const Comp = variant === 'dialog' ? DialogContent : DrawerContent;
  return <Comp {...props} />;
};

export const DrawerDialogHeader = (props?: React.ComponentPropsWithoutRef<typeof DialogHeader>) => {
  const { variant } = useDrawerDialogContext();
  const Comp = variant === 'dialog' ? DialogHeader : PageHeaderGroup;
  return <Comp {...props} />;
};

export const DrawerDialogTitle = (props?: React.ComponentPropsWithoutRef<typeof DrawerTitle>) => {
  const { variant } = useDrawerDialogContext();
  const Comp = variant === 'dialog' ? DialogTitle : PageHeader;
  return <Comp {...props} />;
};

export const DrawerDialogDescription = (
  props?: React.ComponentPropsWithoutRef<typeof DrawerDescription>,
) => {
  const { variant } = useDrawerDialogContext();
  const Comp = variant === 'dialog' ? DialogDescription : PageDescription;
  return <Comp {...props} />;
};

export const DrawerDialogFooter = (props?: React.ComponentPropsWithoutRef<typeof DialogFooter>) => {
  const { variant } = useDrawerDialogContext();
  const Comp = variant === 'dialog' ? DialogFooter : DrawerFooter;
  return <Comp {...props} />;
};

export const DrawerDialogClose = (props?: React.ComponentPropsWithoutRef<typeof DialogClose>) => {
  const { variant } = useDrawerDialogContext();
  const Comp = variant === 'dialog' ? DialogClose : DrawerClose;
  return <Comp {...props} />;
};
