import { Form } from '@remix-run/react';

import { useOptionalUser } from '~/utils/hooks/use-user.ts';

import { AlertCircle } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export const ImpersonationInfo = () => {
  const { t } = useTranslation();
  const user = useOptionalUser();
  return (
    <div
      className={
        'pointer-events-none fixed top-8 left-1/2 z-1 flex w-full -translate-x-1/2 flex-col gap-3'
      }>
      <div
        className={
          'mx-auto flex min-h-10 w-fit min-w-[21.25rem] items-center rounded-full bg-gradient-to-br text-white border-secondary dark:from-card dark:to-background dark:border z-100 from-gray-800 to-gray-900 py-1.5 pr-1.5 pl-3 shadow-xl shadow-black/25 before:pointer-events-none before:absolute before:inset-px before:rounded-full'
        }>
        <span className={'flex items-center gap-2 justify-between'}>
          <span className={'flex items-center gap-2'}>
            <AlertCircle className={'size-4'} />
            <p className={'text-sm font-medium'}>
              {t('impersonation.title', { username: user?.fullName })}
            </p>
          </span>
          <Form method={'post'} action={'/impersonate'}>
            <button
              className={
                'pointer-events-auto font-medium text-xs bg-red-600 rounded-full px-3 py-1.5 hover:bg-red-700 transition-colors duration-150'
              }>
              {t('impersonation.quit')}
            </button>
          </Form>
        </span>
      </div>
    </div>
  );
};
